<template>
  <div class="card card--profile-tasks">
    <div
      v-b-toggle="`collapseShortBio`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse"><i
          data-feather="book"
          class="w-icon-24"
        /> Short biography & research interest</a>
      </h3>
      <div class="heading-elements">
        <span
          class="dis-ib"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Edit my profile"
        ><a
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
          :href="$router.resolve({name: 'researcher.personal-data.index'}).href"
          target="_blank"
        ><i data-feather="edit-3" /></a></span>
        <ul class="list-inline mb-0 disp-in ms-2">
          <li>
            <a data-action="collapse"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            ><polyline points="6 9 12 15 18 9" /></svg></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseShortBio`"
        :visible="fields.find(e => e.name == 'Short biography').visibility"
      >
        <div class="card-body">
          <div
            v-if="!loading"
            class="row"
          >
            <div class="col-sm-6 card--profile-alerts-calendar">
              <input
                ref="fileInput"
                type="file"
                style="display: none"
              >
              <!-- @change="handleFileChange" -->
              <h4>Short biography</h4>
              <hr>
              <p v-html="user.short_biography" />
            </div>
            <div class="col-sm-6 card--profile-alerts-alarms">
              <h4>Research interest</h4>
              <hr>
              <p v-html="user.research_interest" />
            </div>
          </div>
          <div
            v-if="loading"
          >
            <div
              class="loading"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
  },
  mounted() {},
}
</script>
