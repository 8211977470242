<template>
  <div v-if="alerts.length > 0">
    <div class="profile__important__notification">
      <h4>General notifications <span class="badge bg-light-info">{{ alerts.length }}</span></h4>
      <div
        v-for="alert in alerts"
        :key="alert.id"
        class="row d-flex align-items-center general__notification"
      >
        <div class="col-10">
          <small class="badge bg-light-info me-1">{{ alert.date }}</small><span class="text-danger"><i data-feather="bell" /></span> <span
            class="alert-content"
            v-html="alert.message"
          />
        </div>
        <div class="col-2 text-end">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Go to the notification"
            class="me-1"
          >
            <a
              href="#"
              class="btn bg-light-secondary btn-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasReadNotification"
              aria-controls="offcanvasRight"
              @click="$emit('showAlert', alert)"
            >
              <i data-feather="link" />
            </a>
          </span>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Archived general notification"
          >
            <a
              class="btn bg-light-secondary btn-sm"
              @click="read(alert)"
            >
              <i data-feather="archive" />
            </a>
          </span>
        </div>
        <div class="col">
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      userId: this.$route.params.id,
      loading: true,
      filters: {
        begin_date: new Date(),
        is_read: {
          value: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
      alerts: 'alerts/general',
    }),
  },
  async mounted() {
    await this.$store.dispatch('alerts/fetchGeneral', this.filters)
  },
  methods: {
    async read(alert) {
      this.$store.dispatch('alerts/archive', alert.id)
      this.$store.dispatch('alerts/fetchGeneral', this.filters).then(() => {
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 50)
      })
    },
  },
}
</script>
