<template>
  <div class="content-body content-body--senior-call">
    <div class="row">
      <div class="col-12">
        <div class="card card--profile-tasks">
          <div
            v-b-toggle="`collapseContactInformation`"
            class="card-header"
          >
            <h3 class="cart-title">
              <a data-action="collapse"><i
                data-feather="user-check"
                class="w-icon-24"
              /> Contact information</a>
            </h3>
            <div class="heading-elements">
              <!-- <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copy all contact information"
              ><a
                href="#"
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
              ><i data-feather="copy" /></a></span> -->
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit my profile"
              ><a
                :href="$router.resolve({name: 'researcher.personal-data.index'}).href"
                target="_blank"
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
              ><i data-feather="edit-3" /></a></span>
              <ul class="list-inline mb-0 disp-in ms-2">
                <li>
                  <a data-action="collapse"><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-down"
                  ><polyline points="6 9 12 15 18 9" /></svg></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-content">
            <b-collapse
              :id="`collapseContactInformation`"
              :visible="fields.find(e => e.name == 'Contact information').visibility"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-6 card--profile-contact-information">
                    <template>
                      <div class="row">
                        <div class="col">
                          <div class="clearfix">
                            <h3 class="float-start">
                              Contact details
                            </h3>
                          </div>
                          <hr>
                        </div>
                      </div>
                      <div
                        class="row"
                      >
                        <div class="col-sm-3">
                          <p>Personal phone <a
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Copy phone"
                            @click="copyToClipboard(user.phone ? user.phone : user.fix_phone)"
                          ><i data-feather="copy" /></a> <strong><a :href="`tel:${user.phone ? user.phone : user.fix_phone}`">{{ user.phone ? user.phone : user.fix_phone }}</a></strong></p>
                        </div>
                        <template v-if="user.real_professional_background">
                          <div class="col-sm-3">
                            <p>Work Email <a
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Copy email"
                              @click="copyToClipboard(user.real_professional_background.professional_email)"
                            ><i data-feather="copy" /></a> <strong><a :href="`mailto:${user.real_professional_background.professional_email}`">{{ user.real_professional_background.professional_email }}</a></strong></p>
                          </div>
                          <div class="col-sm-3">
                            <p>Work phone <a
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Copy phone"
                              @click="copyToClipboard(user.real_professional_background.phone)"
                            ><i data-feather="copy" /></a> <strong><a :href="`tel:${user.real_professional_background.phone}`">{{ user.real_professional_background.phone }}</a></strong></p>
                          </div>
                          <div class="col-sm-3">
                            <p>Work Address <a
                              v-if="user.real_professional_background && user.real_professional_background.department"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Copy address"
                              @click="copyToClipboard(`${user.real_professional_background.department.address}, ${user.real_professional_background.department.city} ${user.real_professional_background.department.postal_code}`)"
                            ><i data-feather="copy" /></a>
                              <strong>
                                <template v-if="user.real_professional_background && user.real_professional_background.department && user.real_professional_background.department.id">
                                  {{ user.real_professional_background.address_2 }}<br>
                                  {{ user.real_professional_background.department.postal_code }}
                                  {{ user.real_professional_background.department.city }}
                                </template>
                                <template v-else>
                                  {{ user.real_professional_background.institution.address }}<br>
                                  {{ user.real_professional_background.institution.postal_code }}
                                  {{ user.real_professional_background.institution.city }}
                                </template>
                              </strong></p>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                  <div class="col-6 card--profile-contact-information">
                    <template>
                      <div class="row">
                        <div class="col">
                          <div class="clearfix">
                            <h3 class="float-start">
                              More information
                            </h3>
                            <a
                              v-if="user.enter_convo_id"
                              href="#"
                              class="float-end"
                              @click="showEvaluation"
                            >View ICREA candidancy</a>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-sm-2">
                              <p>Next promotion <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="go to promotion call 2026"
                              >
                              <!-- <i data-feather="link" /> -->
                              </a> <strong>{{ user.next_promotion_year }}</strong></p>
                            </div>
                            <div class="col-sm-2">
                              <p>Starting date <strong>{{ user.researcher_start_date }}</strong></p> <!-- Hace referencia a la fecha de cuando se le otorgó el rol de ICREA -->
                            </div>
                            <div
                              v-if="loggedUser.roles.includes('super-admin')"
                              class="col-sm-2"
                            >
                              <p>Level <strong>{{ user.salary_level }}</strong></p>
                            </div>
                            <div
                              v-if="loggedUser.roles.includes('super-admin')"
                              class="col-sm-2"
                            >
                              <p>Birthday & age <strong>{{ user.birthday }} ({{ user.years_old }})</strong></p>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col-md-auto">
                              <p>Main ERC Panel  <strong>{{ user.main_ercs.level3 ? user.main_ercs.level3.code : (user.main_ercs.level2 ? user.main_ercs.level2.code : '-') }}</strong></p>
                            </div>
                            <div class="col-md-3">
                              <p class="m-0">
                                Other ERC Panels
                              </p>
                              <div class="row">
                                <div
                                  v-for="(aerc, index) in user.additional_ercs"
                                  :key="index"
                                  class="col-md-6"
                                >
                                  <strong>{{ aerc.level3 ? aerc.level3.code : (aerc.level2 ? aerc.level2.code : '-') }}</strong>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-auto">
                              <p>Researcher ID (WOS):  <strong>{{ user.researcher_id }}</strong></p>
                            </div>
                            <div class="col-md-auto">
                              <p>Author ID (SCOPUS)  <strong>{{ user.author_id }}</strong></p>
                            </div>
                            <div class="col-md-auto">
                              <p>ORCID  <strong>{{ user.orcid }}</strong></p>
                            </div>
                          </div>
                          <hr>
                          <div class="row">
                            <div class="col">
                              <p>
                                Keywords
                                <strong>
                                  <span
                                    v-for="(keyw, index) in user.keywords"
                                    :key="index"
                                    class="badge bg-secondary me-1"
                                  >
                                    {{ keyw.word }}
                                  </span>
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
  },
  mounted() {},
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.$toastr.success('', 'Copied to clipboard')
    },
    showEvaluation() {
      this.$store.dispatch('modals/toggleFormData', true)
    },
  },
}
</script>
